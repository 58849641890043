import React from "react"
import styled from "styled-components"
import { device } from "./device"

const StyledForm = styled.form`
  width: 100%;
`

const StyledInput = styled.input`
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: solid;
  border-radius: 3px;
  @media ${device.laptop} {
    width: 50%;
  }
`

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: solid;
  border-radius: 3px;
  @media ${device.laptop} {
    width: 50%;
  }
`

const StyledSubmit = styled.input.attrs({
  type: "submit",
  value: "Submit",
})`
  width: 100%;
  background: black;
  color: white;
  cursor: pointer;
  margin-bottom: 0;
  text-transform: uppercase;
  border-radius: 5px;
  height: 35px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  :hover {
    color: red;
  }
  @media ${device.laptop} {
    width: 50%;
  }
`

const ContactFormFS = () => (
  <StyledForm method="POST" action="https://formspree.io/support@badabing.co">
    <StyledInput type="text" name="_gotcha" style={{ display: `none` }} />
    <StyledInput type="text" name="name" id="name" placeholder="Name" />
    <StyledInput type="email" name="_replyto" placeholder="Email Address" />
    <StyledTextArea
      name="message"
      id="message"
      rows="5"
      placeholder="Message"
    />
    <br />
    <StyledSubmit />
  </StyledForm>
)

export default ContactFormFS
