import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactFormFS from "../components/contact-form-fs"
import styled from "styled-components"

const StyledContainer = styled.div`
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 1.45rem;
`

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <StyledContainer>
      <h1>Contact Us</h1>
      <ContactFormFS />
    </StyledContainer>
  </Layout>
)

export default ContactPage
